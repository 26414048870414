import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import sites from "../constants/sites"

import styled from "styled-components"

const About = () => {
  const data = useStaticQuery(graphql`
    query GetMeImage {
      meImage: file(relativePath: { eq: "me.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <SEO title="About" />
      <Layout>
        <StyledImage fluid={data.meImage.childImageSharp.fluid} />
        <section>
          <h2>About Me</h2>
          <p>
            When I was around 5 or 6 years old, my parents bought us a PC, which
            was terribly slow and used dial-up internet. Still, it was exciting
            at the time. A few years later I started experimenting with HTML and
            hosting webpages on Yahoo's Geocities. These days, React, Gatsby,
            and Netlify are some of my favorite technologies to use.
          </p>
          <br />
          <p>
            People have described me as having a relaxed and easygoing
            personality, and a disciplined work-ethic. Whatever I'm working on,
            I like to learn and improve. That's especially true with software
            development, since there's always more to learn and new technologies
            are always coming out.
          </p>
          <br />
          <p>
            I have several hobbies as well. I've studied Japanese since I was a
            teen and enjoyed learning about the culture there. Sometimes I make
            electronic music with Ableton Live. Recently, I've been learning
            about history and economics.
          </p>
        </section>
      </Layout>
    </Wrapper>
  )
}

const StyledImage = styled(Img)`
  border-radius: 10px;
  width: 400px;
  margin: auto;
  margin-bottom: 2.25rem;
`

const Wrapper = styled.div`
  /* padding: 1rem; */
  height: 100vh;
  /* background-color: dodgerblue; */
`

export default About
